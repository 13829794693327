import React from 'react';
import CompShowFooter from './footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons'


const CompShowInicio = () => {

    const goToFirstSection = () => {
        // Desplaza al componente de BranchSelection
        const firstSection = document.getElementById('firstSection');
        if (firstSection) {
            firstSection.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const Card = ({ imageSrc, title, description }) => {
        return (
            <div className="max-w-md overflow-hidden shadow-lg mx-4 my-4 rounded-2xl md:rounded-3xl lg:rounded-4xl">
                <img className="w-full h-60 object-cover object-center" src={imageSrc} alt={title} />
                <div className="px-6 py-4">
                    <div className="font-bold text-white text-xl mb-2">{title}</div>
                    <p className="text-white text-base">{description}</p>
                </div>
            </div>
        );
    };




    return (
        <div className="flex flex-col h-full overflow-y-auto overflow-x-hidden smooth-scroll transition-all " style={{ backgroundColor: 'rgb(37, 32, 70)' }}>
            <header className="!z-[2000] transition-colors duration-300" style={{ backgroundColor: 'transparent', color: 'rgb(255, 255, 255)' }}>
                <div className="relative z-10 grid items-center lg:gap-6 xl:gap-10 mx-auto pt-6 pb-6 container" style={{ gridTemplateColumns: 'auto auto auto' }}>
                    <div className="col-span-2 lg:col-span-1">
                        <a className="grid max-w-full" target="_self" href="/">
                            <h2 className="heading-small lg:heading-medium max-w-full whitespace-nowrap overflow-hidden overflow-ellipsis text-4xl font-bold" style={{ color: 'rgb(255, 255, 255)', fontFamily: 'var(--header-logo-fontFamily)', fontWeight: 'var(--header-logo-fontWeight)' }}>
                                Tauket
                            </h2>
                        </a>
                    </div>
                    <div className="hidden lg:flex item-center justify-end gap-10 lg:col-span-2">
                        <div className='hidden lg:flex item-center gap-10'>
                            <ul className='lg:flex items-center gap-x-4 gap-y-2 flex-wrap justify-end hidden text-white'>
                                <a href="https://wa.me/5493814626117?text=hola%20vengo%20de%20Tauket.com" target='_blank'>  <FontAwesomeIcon icon={faWhatsapp} className='text-4xl cursor-pointer hover:text-5xl transition-all' /></a>
                                <a href='https://www.instagram.com/tauket_/' target='_blank'> <FontAwesomeIcon icon={faInstagram} className='text-4xl cursor-pointer hover:text-5xl transition-all' /></a>
                            </ul>
                        </div>
                    </div>
                    <div className="ml-auto lg:hidden">
                        <button className="inline-flex items-center justify-center p-2 rounded-md focus:outline-none transition-colors duration-300" style={{ color: 'rgb(255, 255, 255)' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="block h-5 w-5">
                                <path fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10zm0 5.25a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75z" clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </header>



            <section className="relative">
                <div
                    data-text-color="#FFFFFF"
                    className="flex relative break-word items-center"
                    style={{ marginTop: '-88px', paddingTop: '88px', zIndex: '39', minHeight: 'calc(0px + 100vh)', paddingBottom: '0px' }}
                    data-combine-with-header="true"
                >
                    <div className="w-full flex break-word transition-all duration-300 items-center">
                        <div className="absolute inset-0 z-10 pointer-events-none">
                            <div className="absolute inset-0 z-10" style={{ backgroundColor: 'rgb(74, 63, 140)', opacity: '0.7' }}></div>
                            <div className="absolute inset-0 z-5" style={{ backdropFilter: 'blur(4px)' }}></div>
                            <div className="absolute inset-0 z-0 bg-white">
                                <span
                                    style={{
                                        boxSizing: 'border-box',
                                        display: 'block',
                                        overflow: 'hidden',
                                        width: 'initial',
                                        height: 'initial',
                                        background: 'none',
                                        opacity: '1',
                                        border: '0px',
                                        margin: '0px',
                                        padding: '0px',
                                        position: 'absolute',
                                        inset: '0px',
                                    }}
                                >
                                    <img
                                        alt="background"
                                        sizes="200vw"
                                        src='/bg.jpg'
                                        decoding="async"
                                        data-nimg="fill"
                                        style={{
                                            position: 'absolute',
                                            inset: '0px',
                                            boxSizing: 'border-box',
                                            padding: '0px',
                                            border: 'none',
                                            margin: 'auto',
                                            display: 'block',
                                            width: '0px',
                                            height: '0px',
                                            minWidth: '100%',
                                            maxWidth: '100%',
                                            minHeight: '100%',
                                            maxHeight: '100%',
                                            objectFit: 'cover',
                                            objectPosition: '50% 50%',
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="relative z-10 container mx-auto px-5 md:px-6 transition-all duration-300 pt-20 lg:pt-48 pb-20 lg:pb-48">
                            <div className="relative z-10 w-full h-full">
                                <div className="flex w-full gap-10 lg:gap-20 transition-all duration-300 flex-col lg:flex-row justify-center">
                                    <div className="flex-1 flex flex-col w-full max-w-3xl items-center text-center mx-auto">
                                        <h2 className="mb-6 break-word transition-all duration-300 text-center heading-xlarge text-5xl font-bold" style={{ color: 'rgb(255, 255, 255)' }}>
                                            Mejora tu presencia en línea
                                        </h2>
                                        <p className="transition-all duration-300 body-large text-2xl" style={{ color: 'rgb(255, 255, 255)' }}>
                                            Elevando tu presencia digital, un click a la vez.
                                        </p>
                                        <div className="flex md:inline-flex flex-col md:flex-row md:gap-4 w-full md:w-max">
                                            <a
                                                className="button w-full md:w-max mt-6 lg:mt-8 lg text-2xl p-3 cursor-pointer"
                                                target="_self"
                                                style={{
                                                    borderWidth: '2px',
                                                    borderStyle: 'solid',
                                                    boxShadow: 'none',
                                                    fontFamily: 'var(--body-fontFamily)',
                                                    fontWeight: 'var(--body-fontWeight, 500)',
                                                    fontStyle: 'var(--body-fontStyle)',
                                                    backgroundColor: 'rgb(234, 102, 124)',
                                                    color: 'rgb(17, 24, 39)',
                                                    borderRadius: '8px',
                                                    borderColor: 'rgb(234, 102, 124)',
                                                }}
                                                onClick={goToFirstSection}
                                            >
                                                Ver más
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section id='firstSection' className="relative items-start">
                <div className="ml-0 md:ml-24 rich-text-block max-w-5xl text-left mr-auto mt-20" style={{ color: 'rgb(255, 255, 255)' }}>
                    <h3 className='text-2xl p-12 md:text-3xl md:p-0 my-46' style={{ whiteSpace: 'pre-line' }}>En Tauket, nos especializamos en marketing digital para ayudar a las empresas a crecer en línea.
                        Entendemos el mercado local y sus necesidades únicas. Contamos con un equipo altamente capacitado y enfocado en crear estrategias
                        de marketing efectivas. Nuestro objetivo es mejorar tu presencia en línea y obtener resultados mediante soluciones personalizadas.</h3>
                    <h2 className="text-center md:text-left mb-7 text-4xl font-bold text-white mt-[12rem]">Servicios</h2>
                </div>
                <div className="flex flex-wrap justify-center gap-8">
                    <Card
                        imageSrc="/s1.webp"
                        title="Estrategias de Marketing de Contenido"
                        description="Te ayudamos a crear y gestionar contenido atractivo para atraer a tu audiencia objetivo y mejorar tu presencia en línea mediante campañas digitales."
                    />
                    <Card
                        imageSrc="/s3.jpg"
                        title="Gestión de Redes Sociales"
                        description="Gestionamos tu marca en las redes sociales y otros canales digitales construyendo y manteniendo relaciones positivas con la audiencia en línea, fomentando la participación y la interacción."
                    />
                    <Card
                        imageSrc="/s2.jpg"
                        title="Servicio de Optimización SEO/SEM"
                        description="Optimiza tu sitio web para mejorar el ranking en los motores de búsqueda y atraer más tráfico relevante a tu sitio."
                    />
                </div>
            </section>

            <section className="hidden lg:block relative mt-36 top-24 "   >
                <div className='slanted-section ' style={{ transformOrigin: 'top', transform: 'skew(0, -4.76deg)' }}>
                    <div
                        className="flex relative break-word items-start "

                        style={{ zIndex: 36, minHeight: 'calc(134px)', marginTop: '-83px', paddingTop: '83px', paddingBottom: '0px', transform: 'skew(0, 4.76deg)' }}
                        data-text-color="#FFFFFF"
                        data-combine-with-header="false"
                    >
                        <div className="w-full flex break-word transition-all duration-300 items-start ">
                            <div className="absolute inset-0 z-10 pointer-events-none ">
                                <div
                                    className="absolute inset-0 z-10 "
                                    style={{ backgroundColor: 'rgb(74, 63, 140)' }}
                                ></div>
                            </div>
                            <div className="relative z-10 container mx-auto px-5 md:px-6 transition-all duration-300 pt-20 lg:pt-48 pb-0 ">
                                <div className="relative z-10 w-full h-full ">
                                    <div className="flex w-full gap-10 lg:gap-20 transition-all duration-300 flex-col lg:flex-row justify-start">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="relative">

                <div
                    className="flex flex-none flex-shrink-0 relative break-word items-center"
                    style={{ minHeight: 'calc(134px)', zIndex: 35, paddingTop: '0px', paddingBottom: '0px' }}
                >
                    <div className="absolute inset-0 z-10 pointer-events-none">
                        <div
                            className="absolute inset-0 z-10"
                            style={{ backgroundColor: 'rgb(74, 63, 140)' }}
                        ></div>
                    </div>


                    <div className="relative z-10 container mx-auto pt-12 lg:pt-20 pb-12 lg:pb-20">
                        <div className="flex w-full gap-10 lg:gap-20 flex-col lg:flex-row items-center">
                            <div className="flex-1 flex flex-col max-w-240 items-start p-10 md:p-0">
                                <div className="rich-text-block" style={{ color: 'rgb(255, 255, 255)' }}>
                                    <h2 className='text-5xl font-bold' style={{ whiteSpace: 'pre-line' }}>Servicios de Marketing en Redes Sociales</h2>
                                    <p className='mt-10 text-xl'>
                                        Ayudamos a mejorar la presencia de tu negocio en las redes sociales más populares. Nuestros expertos utilizan técnicas probadas para aumentar tu visibilidad en línea, lo que genera más tráfico y potenciales clientes para tu negocio.
                                    </p>

                                </div>
                            </div>
                            <div className="flex-1 flex w-full h-full justify-center lg:justify-start">
                                <div className="flex-shrink-0 relative w-full h-full mx-auto aspect-w-3 aspect-h-2">
                                    <span
                                        style={{
                                            boxSizing: 'border-box',
                                            display: 'block',
                                            overflow: 'hidden',
                                            width: 'initial',
                                            height: 'initial',
                                            background: 'none',
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0
                                        }}
                                    >
                                        <img
                                            alt="a group of different types of social media logos"
                                            title="a group of different types of social media logos"
                                            src="/1.webp"
                                            decoding="async"
                                            className="rounded-2xl md:rounded-3xl lg:rounded-4xl"
                                            style={{
                                                boxSizing: 'border-box',
                                                padding: 0,
                                                border: 'none',
                                                margin: 'auto',
                                                display: 'block',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                minHeight: '100%',
                                                maxHeight: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative  ">
                <div className="container mx-auto py-12 lg:py-20 mt-12">
                    <div className="flex flex-col-reverse md:flex-col lg:flex-row items-center justify-around lg:justify-around gap-10 lg:gap-20">

                        <div className="flex-1 max-w-full sm:max-w-[80vw] md:max-w-[60vw] lg:max-w-[40vw]"> {/* Ajuste del tamaño del contenedor */}
                            <div className="w-full">
                                <div className="aspect-w-16 aspect-h-9">
                                    <img
                                        alt="white and pink digital device"
                                        title="white and pink digital device"
                                        src="/2.webp"
                                        className="w-full h-auto rounded-2xl md:rounded-3xl lg:rounded-4xl object-cover"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-1 max-w-xl p-10 md:p-0">
                            <div className="rich-text-block text-white">
                                <h2 className="text-5xl font-bold mb-6">Sobre nosotros</h2>
                                <p className="text-xl mb-8">Somos una agencia de marketing digital ubicada en Tucumán, Argentina. Nos dedicamos a desbloquear el potencial digital de cada negocio,
                                    transformando ideas en resultados tangibles. Con pasión por la innovación y una meticulosa atención a los detalles, ofrecemos soluciones personalizadas que impulsan el crecimiento y
                                    la visibilidad de nuestros clientes en el vasto mundo digital.</p>
                                <p className="text-xl">Nuestros servicios abarcan una variedad de necesidades de marketing que incluyen SEO/SEM, gestión de redes sociales, creación de contenido, análisis de competencia y análisis objetivos.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="flex flex-col items-center justify-center py-20 bg-gradient-to-r from-purple-700 to-indigo-700 text-white">
                <h2 className="text-4xl font-bold mb-6">Contáctanos</h2>
                <p className="text-lg mb-8 text-center">Estamos aquí para ayudarte. ¡Conéctate con nosotros a través de nuestras redes sociales!</p>
                <div className="flex gap-6">
                    <a href="https://wa.me/5493814626117?text=hola%20vengo%20de%20Tauket.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faWhatsapp} className="text-4xl cursor-pointer hover:text-5xl transition-all" />
                    </a>
                    <a href="https://www.instagram.com/tauket_/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className="text-4xl cursor-pointer hover:text-5xl transition-all" />
                    </a>
                </div>
            </section>


            <CompShowFooter></CompShowFooter>
        </div>
    );
};

export default CompShowInicio;
